import React, { Component } from "react";
import styled from "styled-components";
import { Link } from "gatsby";

import Form from "./Form";
import Image from "../Image";
import { Background } from "../SectionEarlyAccess";
import Back from "../Thanks/Back";

const Section = styled.section`
  position: relative;
  min-height: 60rem;
  height: 100vh;
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: center;
  background-color: #00001a;

  @media (max-width: 750px) {
    align-items: unset;

    #mobile-fan img {
      opacity: 0;
      display: none;
    }
    #footer-background {
      &::after {
        content: " ";
        position: absolute;
        top: 0;
        left: 0;
        height: 100%;
        width: 100%;
        background-color: red;
        z-index: 20;
      }
    }
  }
`;

const Gradient = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  height: 30%;
  width: 100%;
  z-index: 2;
  pointer-events: none;
  background: linear-gradient(to top, rgba(0, 0, 26, 0), #00001a);
`;

const Title = styled.div`
  max-width: 30rem;
  width: 100%;
  padding: 4rem;
  padding-bottom: 0;
  position: absolute;
  top: 0;
  left: 50%;
  transform: translateX(-50%);
  z-index: 20;

  @media (max-width: 750px) {
    position: relative;
    transform: none;
    left: 0;
    padding-top: 2rem;
    width: 80%;
  }
`;

const BackButton = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  padding: 2rem;
  z-index: 15;
`;

// const DiscordCTA = styled.div`
//   position: absolute;
//   z-index: 20;
//   top: 2rem;
//   right: 2rem;
// `;

export default class RedeemContent extends Component {
  state = {
    windowWidth: false
  };

  componentDidMount() {
    this.attachEventListeners();
    this.handleResize();
  }

  componentWillUnmount() {
    window.removeEventListener("resize", this.handleResize);
  }

  attachEventListeners() {
    window.addEventListener("resize", this.handleResize);
  }

  handleResize = e => {
    this.setState({ windowWidth: window.innerWidth });
  };

  render() {
    const { windowWidth } = this.state;
    const { showBackButton, showLogo, className, creatorSignup } = this.props;

    return (
      <Section className={className} id="redeeem">
        {windowWidth && windowWidth > 750 && showBackButton && (
          <BackButton>
            <Back />
          </BackButton>
        )}

        {showLogo && (
          <Title>
            <Link to="/">
              <Image src="/images/skyweaver-logo.svg" alt="Skyweaver Logo" />
            </Link>
          </Title>
        )}

        <Form creatorSignup={creatorSignup} />

        {/* {windowWidth && windowWidth < 750 && <MobileBack />}

        <Footer windowWidth={windowWidth} /> */}

        <Gradient className="gradient" gradientDirection="top" />

        <Background className="background" height={"90%"} top={"10%"}>
          <Image src="/images/6-early/background.jpg" />
        </Background>
      </Section>
    );
  }
}
