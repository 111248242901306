import React, { Component } from "react";
import styled from "styled-components";
import queryString from "query-string";

import { H2, P } from "../Typography";

const Section = styled.section`
  padding: 3rem;
  max-width: 60rem;
  width: 100%;
  position: relative;
  z-index: 30;
  text-align: center;

  button {
    outline: none;
    transition: 0.2s ease-out transform;

    &:active {
      transform: scale(0.95, 0.95);
    }
  }

  @media (max-width: 750px) {
    width: 100%;
    max-width: 40rem;
    padding: 3rem 0;
  }
`;



const Text = styled.div`
  @media (max-width: 750px) {
    padding: 0 6rem;
  }
`;


export default class FormSection extends Component {
  state = {
    codeVal: undefined,
    utmCampaign: "",
    utmSource: "",
    utmMedium: "",
    utmContent: ""
  };

  componentDidMount() {
    const parsed = queryString.parse(window.location.search);

    if (parsed.code) {
      this.setState({ codeVal: parsed.code });
    }

    if (parsed.utm_campaign) {
      this.setState({ utmCampaign: parsed.utm_campaign });
    }

    if (parsed.utm_source) {
      this.setState({ utmSource: parsed.utm_source });
    }

    if (parsed.utm_medium) {
      this.setState({ utmMedium: parsed.utm_medium });
    }

    if (parsed.utm_content) {
      this.setState({ utmContent: parsed.utm_content });
    }
  }

  render() {
    // const { utmCampaign, utmContent, utmMedium, utmSource } = this.state;
    // const { creatorSignup } = this.props;

    const subtitle = `Sign up to our beta waitlist so we can invite you into the game when it’s ready, and to stay up to date on what’s happening in Skyweaver!`;

    return (
      <Section>
        <H2 color="#FFF">Join the<br /> private beta waitlist</H2>

        <script id="convertful-api" src="https://app.convertful.com/Convertful.js?owner=20234" async></script>
        <Text>
          <P>
            <span className="subtitle" dangerouslySetInnerHTML={{ __html: subtitle }} />
          </P>
        </Text>
        <div style={{ maxWidth: '550px', margin: '0 auto' }}>
          <div className="convertful-79171">

          </div>
          <P style={{ fontSize: 14, fontWeight: 500, marginTop: '0px', position: 'relative', top: '-10px' }}>
            By clicking Sign Up, you agree to receive communications from Skyweaver. You can unsubscribe anytime.
          </P>
        </div>

      </Section>
    );
  }
}