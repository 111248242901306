import React, { Component } from "react";

import "../css/main.css";
import "../css/fonts.css";

import Seo from "../components/SeoHead";
import Signup from "../components/Signup-test/";
import Helmet from 'react-helmet'

export default class Redeem2 extends Component {
  render() {
    return (
      <>
        <Seo />
        <Signup showBackButton showLogo />
        <Helmet>
        </Helmet>
      </>
    );
  }
}
